<style scoped>
.layout {
  border: 0;
  background: #f5f7f9;
  position: relative;
  border-radius: 0;
  max-height: 100vh;
}

.layout-header-bar {
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
}

.layout-logo-left {
  width: 90%;
  height: 30px;
  background: #5b6270;
  border-radius: 3px;
  margin: 15px auto;
}

.menu-icon {
  transition: all .3s;
}

.rotate-icon {
  transform: rotate(-90deg);
}

/*.ivu-menu-dark {*/
/*  background-color: #17233d;*/
/*}*/

/*.ivu-menu-dark.ivu-menu-submenu {*/
/*  background-color: #17233d;*/
/*}*/

.menu-item span {
  display: inline-block;
  overflow: hidden;
  width: 69px;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
  transition: width .2s ease .2s;
}

.menu-item i {
  transform: translateX(0px);
  transition: font-size .2s ease, transform .2s ease;
  vertical-align: middle;
  font-size: 16px;
}

.collapsed-menu span {
  width: 0;
  transition: width .2s ease;
}

.collapsed-menu i {
  transform: translateX(5px);
  transition: font-size .2s ease .2s, transform .2s ease .2s;
  vertical-align: middle;
  font-size: 22px;
}

.ivu-layout-content {
  min-height: calc(100vh - 104px);
}

/*/deep/ .ivu-layout-sider {*/
/*  background-color: #17233d;*/
/*}*/

.logo-img {
  width: 61%;
  margin-top: 4px;
  margin-left: 8px;
}
</style>
<template>
  <div class="layout">
    <Layout>
      <Sider ref="side" hide-trigger collapsible :collapsed-width="78"
        :style="{ position: 'fixed', height: '100vh', left: 0, overflow: 'auto' }" v-model="isCollapsed">
        <div class="logo" style="height: 64px; padding-left: 11px">
          <img src="../assets/ipaas_logo.png" class="logo-img" v-if="!isCollapsed" />
          <Avatar icon="person" size="large" v-else />
        </div>
        <AsideMenu></AsideMenu>
      </Sider>
      <Layout :style="{ marginLeft: '200px' }">
        <Header :style="{ padding: 0 }" class="layout-header-bar">
          <!--          <Icon @click.native="collapsedSider" :class="rotateIcon" :style="{margin: '0 20px'}" type="md-menu" size="24"></Icon>-->
          <span style="font-size:18px; font-weight:bold; margin-left: 20px">IPAAS 管理系统</span>
          <span style="float: right; margin-right: 20px">
            <span style="margin-right: 12px; font-size: 19px">{{ account }}</span>
            <a @click="logout">
              <i class="iconfont icon-UIsheji_menjinxitong-32"></i>
              退出登录
            </a>
          </span>
        </Header>
        <Content class="content" :style="{ margin: '20px', background: '#f5f7f9', textAlign: 'center' }">
          <router-view></router-view>
        </Content>
      </Layout>
    </Layout>
  </div>
</template>
<script>
import AsideMenu from "./aside/AsideMenu.vue";
import jwt_decode from "jwt-decode";

export default {
  components: {
    AsideMenu
  },
  data() {
    return {
      isCollapsed: false
    }
  },
  computed: {
    account() {
      let token = localStorage.getItem('token')
      if (!token) return ""
      let tokenData = jwt_decode(token)
      return tokenData.account
    },
    rotateIcon() {
      return [
        'menu-icon',
        this.isCollapsed ? 'rotate-icon' : ''
      ];
    },
    menuitemClasses() {
      return [
        'menu-item',
        this.isCollapsed ? 'collapsed-menu' : ''
      ]
    }
  },
  methods: {
    collapsedSider() {
      this.$refs.side.toggleCollapse();
    },
    logout() {
      localStorage.clear();
      window.location.href = "/"
    }
  }
}
</script>